import config from '../config';

/* **
 * ユーザーの操作権限があるかチェックする
 * @param userInfo: userProperty の userInfo を指定
 * @param type: userExecutionTypes の type を指定
 */
export const isUserExecution = (userInfo, type) => {

  // 初期値(データ管理者・一般ユーザーの場合は操作権限無し)
  let result = false;

  // システム管理者はユーザーの全操作可能 
  if (userInfo.group === config.GROUP_SYSTEM) {
    result = true;
  }
  else if (userInfo.group === config.GROUP_SERVICE || userInfo.group === config.USER_GROUP_USER_ADMIN) {
    // サービス管理者の場合 
    switch (type) {
      case userExecutionTypes.list:
      case userExecutionTypes.search:
        result = true;
        break;
      case userExecutionTypes.add:
      case userExecutionTypes.update:
      case userExecutionTypes.abolition:
      case userExecutionTypes.delete:
        result = false;
        // 権限委譲ユーザーのみ実行可能 
        if (userInfo.isDelegated) {
          result = true;
        }
        break;
    }
  }
  return result;
};

/* **
 * 会社の操作権限があるかチェックする 
 * @param userInfo: userProperty の userInfo を指定
 * @param type: companyExecutionTypes の type を指定
 */
export const isCompanyExecution = (userInfo, type) => {

  // 初期値(データ管理者・一般ユーザーの場合は操作権限無し)
  let result = false;

  // 店舗ユーザーの場合は会社の全操作不可
  if (!userInfo.isAlongToCompany) {
    result = false;
    return result;
  }

  // システム管理者は会社の全操作可能 
  if (userInfo.group === config.GROUP_SYSTEM) {
    result = true;
  }
  else if (userInfo.group === config.GROUP_SERVICE || userInfo.group === config.GROUP_USER_ADMIN) {
    // サービス管理者の場合 
    switch (type) {
      case companyExecutionTypes.list:
      case companyExecutionTypes.search:
        result = true;
        break;
      case companyExecutionTypes.add:
      case companyExecutionTypes.update:
      case companyExecutionTypes.abolition:
      case companyExecutionTypes.delete:
        // Delegated users can access these functions 
        result = userInfo.isDelegated;
        break;
    }
  }
  return result;
};

/* **
 * 店舗の操作権限があるかチェックする  
 * @param userInfo: userProperty の userInfo を指定
 * @param type: shopExecutionTypes の type を指定
 */
export const isShopExecution = (userInfo, type) => {

  // 初期値(データ管理者・一般ユーザーの場合は操作権限無し)
  let result = false;

  // システム管理者は店舗の全操作可能 
  if (userInfo.group === config.GROUP_SYSTEM) {
    result = true;
  }
  else if (userInfo.group === config.GROUP_SERVICE || userInfo.group === config.GROUP_USER_ADMIN) {
    // サービス管理者の場合 
    switch (type) {
      case shopExecutionTypes.list:
      case shopExecutionTypes.search:
        result = true;
        break;
      case shopExecutionTypes.add:
      case shopExecutionTypes.update:
      case shopExecutionTypes.abolition:
      case shopExecutionTypes.delete:
        // 店舗ユーザーの場合は操作不可 
        if (!userInfo.isAlongToCompany) {
          return false;
        }
        result = false;
        // 権限委譲ユーザーのみ実行可能 
        if (userInfo.isDelegated) {
          result = true;
        }
        break;
    }
  }
  return result;
};

/**
 * 非公開 PFC ファイル一覧に対する操作の権限があるか判定
 * @param auth サインインユーザーの権限区分
 */
export const hasUnpublishedPermission = (auth) => {
  return auth === config.GROUP_SYSTEM || auth === config.GROUP_SERVICE || auth === config.GROUP_REPRO_FILE_ADMIN || auth === config.GROUP_REPRO_FILE_TESTER || auth === config.GROUP_SBR_REPRO_TESTER;
};

/**
 * 自動ログインするかのチェック
 * @param redirectionRequired リダイレクト要求の有無
 * @param domainType ドメイン種別
 * @param samlAccountList 連携先のリスト情報
 * @param isExternalError 既にエラーが発生しているかの有無
 */
export const isExecuteAutoLogin = (redirectionRequired, domainType, samlAccountList, isExternalError) => {
  let result = false;
  // SUBARU以外が自動ログイン対象
  if (domainType !== config.SBR_DOMAIN) {
    // 情報が揃っているかのチェック
    if (redirectionRequired && samlAccountList.length === 1) {
      if (!isExternalError) {
        result = true;
      }
    }
  }
  return result;
};

// ユーザーの動作種別
export const userExecutionTypes = {
  list: 1,
  add: 2,
  update: 3,
  search: 4,
  abolition: 5,
  delete: 6,
};

// 会社の動作種別
export const companyExecutionTypes = {
  list: 1,
  add: 2,
  update: 3,
  search: 4,
  abolition: 5,
  delete: 6,
};

// 店舗の動作種別
export const shopExecutionTypes = {
  list: 1,
  add: 2,
  update: 3,
  search: 4,
  abolition: 5,
  delete: 6,
};

export default {
  isCompanyExecution,
  isShopExecution,
  isUserExecution,
  hasUnpublishedPermission,
  isExecuteAutoLogin,
  shopExecutionTypes,
  companyExecutionTypes,
  userExecutionTypes,
};